// import useMediaQuery from "@/hooks/useMediaQuery"
import { Link } from "gatsby"
import { useEffect, useState } from "react"
import styled from "styled-components"

// import loadable from "@loadable/component"
// const Auth = loadable(() => import("@/aws/AmplifyAuthConfigure"))
// import Auth from "@/aws/AmplifyAuthConfigure"

export const ButtonBase = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: transparent;
  line-height: 1.35;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  color: inherit;
`

const NotarizationButtonRoot = styled(ButtonBase)`
  width: 220px;
  min-height: 55.59px;
  font-weight: 600;
  background-color: ${p => p.theme.colors.primary};
  color: ${p => p.theme.colors.black};
`

function NotarizationButton({ signIn, ...props }) {
  const [status, setStatus] = useState("")
  useEffect(() => {
    if (signIn) {
      ;(async function onLoad() {
        const Auth = (await import("@/aws/AmplifyAuthConfigure")).default
        Auth.currentSession()
          .then(() => {
            setStatus("Go back to Course")
          })
          .catch(() => setStatus("Course Sign In"))
      })()
    }
  }, [])

  return (
    <>
      {signIn ? (
        <NotarizationButtonRoot
          to={status === "Course Sign In" ? "/login" : "/course"}
          as={Link}
          {...props}
        >
          {status}
        </NotarizationButtonRoot>
      ) : (
        <NotarizationButtonRoot
          href="https://app.ronary.com/auth"
          target="_blank"
          as="a"
          {...props}
        >
          My Ronary Sign In
        </NotarizationButtonRoot>
      )}
    </>
  )
}

export default NotarizationButton
