import styled from "styled-components"

const ContainerRoot = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 18px;

  @media (min-width: 420px) {
    padding: 0 32px;
  }

  @media (min-width: 480px) {
    padding: 0 48px;
  }

  @media (min-width: 1400px) {
    max-width: 1592px;
    padding: 0 96px;
    margin: 0 auto;
  }
`

function Container({ children, ...rest }) {
  return <ContainerRoot {...rest}>{children}</ContainerRoot>
}

export default Container
